<template>
<div class="cookiepolicy-container" :style="{height: innerHeight + 'px'}" :class="{'full-page': $route.meta.fullPage}">
    <div class="top">
        <div class="header">
            <h2 style="margin: 0;">Cookie Policy</h2>
            <p style="margin: 0;">La presente Cookie Policy costituisce parte integrante dell'informativa privacy consultabile sul nostro sito https://www.tourial.it.</p>
        </div>

    </div>

    <h4>Dovere di informativa</h4>
    <p>L'utente può esprimere il proprio consenso all'utilizzo dei cookie mediante l'accettazione degli stessi in modo libero, specifico, informato ed inequivocabile, cliccando su uno dei link interni delle pagine del sito, oppure cliccando sul tasto "x” o comunque di chiusura del banner dell'informativa breve visibile ad ogni primo accesso sul sito stesso (come previsto dal provvedimento del Garante per la protezione dei dati personali n. 229 dell'8 maggio 2014 e dalle "Linee guida cookie e altri strumenti di tracciamento - 10 giugno 2021”) ovvero compiendo un'azione di scorrimento, quando il sito utilizza solamente cookies tecnici o con funzioni analoghe, fermo restando l'obbligo di informazione da parte del Titolare del trattamento, Tourial nella persona della signora Elena Colombo. </p>

    <h4>Definizione e tipologia di cookies</h4>
    <p>I cookie sono costituiti da porzioni di codice (file informatici o dati parziali) inviate da un server al browser internet dell'utente, da detto browser memorizzati automaticamente sul computer dell'utente e rinviati automaticamente al server ad ogni occorrenza o successivo accesso al sito. Solitamente un cookie contiene il nome del sito internet dal quale il cookie stesso proviene, la durata vitale del cookie (ovvero per quanto tempo rimarrà sul dispositivo dell'utente) ed un valore, che di regola è un numero unico generato in modo casuale. Ad ogni visita successiva i cookie sono rinviati al sito web che li ha originati (cookie di prime parti) o a un altro sito che li riconosce (cookie di terze parti). I cookie sono utili perché consentono a un sito web di riconoscere il dispositivo dell'utente e hanno diverse finalità come, per esempio, consentire di navigare efficientemente tra le pagine, ricordare i siti preferiti e, in generale, migliorare l'esperienza di navigazione. Contribuiscono anche a garantire che i contenuti pubblicitari visualizzati online siano più mirati ad un utente e ai suoi interessi.</p>
    <p>I siti utilizzano diverse tipologie di cookie ognuna delle quali ha una specifica funzione. Il sito utilizza i cookie per rendere la navigazione più semplice e per meglio adattare sia le pagine caricate che i propri prodotti agli interessi e ai bisogni degli utenti. I cookie possono essere usati anche per velocizzare le future esperienze e le attività degli utenti sul sito. Inoltre, sono utilizzati cookie per compilare statistiche anonime aggregate che consentono di capire come gli utenti utilizzano il sito e per migliorare la struttura e i contenuti dei siti stessi.</p>
    <p>Di seguito un elenco esplicativo.</p>

    <h4>Cookie tecnici</h4>
    <p>I cookie "tecnici” sono essenziali per il corretto funzionamento del sito e consentono agli utenti di navigare sul sito e di sfruttarne le caratteristiche (ad esempio consentono la memorizzazione di azioni precedenti o permettono di salvare la sessione dell'utente e/o di svolgere altre attività strettamente necessarie al funzionamento del sito).</p>
    <p>A questa categoria appartengono anche i cookie "analitici” che aiutano a capire come gli utenti interagiscono con il sito fornendo informazioni relative all'ultima pagina visitata, il numero di sezioni e pagine visitate, al tempo trascorso sul sito e ad ogni vicenda sia emersa nel corso della navigazione, come ad esempio un messaggio di errore, e aiutano a comprendere ogni difficoltà che l'utente incontra nell'utilizzo del sito. Queste informazioni potrebbero essere associate a dettagli dell'utente quali indirizzo IP, dominio o browser; tuttavia, vengono analizzate insieme a informazioni di altri utenti in modo da non identificare un determinato utente rispetto all'altro. Questi cookie vengono raccolti e aggregati in forma anonima e consentono al gestore del sito di migliorare l'uso del sito stesso.</p>
    <p>Infine, i cookie di "funzionalità” permettono al sito di ricordare le scelte dell'utente (per esempio il nome utente) per fornire a quest'ultimo una navigazione più personalizzata ed ottimizzata. I cookie funzionali non sono indispensabili al funzionamento dei Siti, ma ne migliorano la qualità e l'esperienza di navigazione. Se non si accettano questi cookie, la resa e la funzionalità del sito potrebbero risultare inferiori e l'accesso ai contenuti del sito potrebbe risultare limitato.</p>

    <h4>Cookie di profilazione</h4>
    <p>I cookie di "targeting” o di "profilazione” sono utilizzati per presentare contenuti più adatti all'utente e ai suoi interessi. Possono essere utilizzati per visualizzare pubblicità mirate, per pubblicare inserzioni pubblicitarie personalizzate in base agli interessi dell'utente o per limitare il numero di volte che l'utente visualizza una pubblicità. Inoltre, aiutano a misurare l'efficacia delle campagne pubblicitarie sul sito; il gestore del sito potrebbe usare questi cookie per ricordare i siti che l'utente ha visitato e condividere queste informazioni con terze parti, incluse agenzie e inserzionisti che potrebbero usare i loro cookie per raccogliere informazioni sulle attività che compiute dagli utenti sul sito.</p>
    <p>I cookie di "condivisione” (o di social network) sono necessari per permettere all'utente di interagire con i Siti attraverso il proprio account social e servono, ad esempio, per esprimere un apprezzamento e per condividerlo con i relativi contatti social.</p>
    <p>Il sito utilizza o può utilizzare, anche in combinazione tra di loro, cookie:</p>

    <ul>
        <li>di "sessione” che vengono memorizzati sul computer dell'utente per mere esigenze tecnico-funzionali, per la trasmissione di identificativi di sessione necessari per consentire l'esplorazione sicura ed efficiente del sito; essi si cancellano al termine della "sessione” (da cui il nome) con la chiusura</li>
        <li>"persistenti” che rimangono memorizzati sul disco rigido del computer fino alla loro scadenza o cancellazione da parte degli utenti. Tramite i cookie persistenti gli utenti che accedono ai siti (o eventuali altri utenti che impiegano il medesimo computer) vengono automaticamente riconosciuti a ogni visita</li>
        <li>"di terza parte”. Quando un utente utilizza il sito, è possibile che vengano memorizzati alcuni cookie non controllati dal gestore del sito. Questo accade, per esempio, se l'utente visita una pagina che contiene contenuti di un sito web di terzi. Di conseguenza, l'utente riceverà cookie da questi servizi di terze parti. Sui cookie di terza parte il gestore del sito non ha il controllo delle informazioni fornite dal cookie e non ha accesso a tali dati. Queste informazioni sono controllate totalmente dalle società terze secondo quanto descritto nelle rispettive privacy policy.</li>
    </ul>

    <vs-table striped>
        <template #thead>
            <tr style="background-color: var(--primary);">
                <td>
                    TIPOLOGIA
                </td>
                <td>
                    DENOMINAZIONE
                </td>

                <td>
                    PROVENIENZA
                </td>
                <td>
                    SCADENZA
                </td>

            </tr>
        </template>
        <template #tbody>
            <tr>
                <td>
                    Tecnico
                </td>
                <td>
                    G_ENABLED_IDPS
                </td>
                <td>
                    Google
                </td>
                <td>
                    1 anno
                </td>
            </tr>
            <tr>
                <td>
                    Tecnico
                </td>
                <td>
                    __stripe_sid
                </td>
                <td>
                    Stripe
                </td>
                <td>
                    1 anno
                </td>
            </tr>
            <tr>
                <td>
                    Tecnico
                </td>
                <td>
                    __stripe_mid
                </td>
                <td>
                    Stripe
                </td>
                <td>
                    1 anno
                </td>
            </tr>
            <tr>
                <td>
                    Tecnico
                </td>
                <td>
                    TOURIAL-SESSION
                </td>
                <td>
                    Tourial
                </td>
                <td>
                    14 giorni
                </td>
            </tr>
            <tr>
                <td>
                    Tecnico
                </td>
                <td>
                    TOURIAL-X
                </td>
                <td>
                    Tourial
                </td>
                <td>
                    6 mesi
                </td>
            </tr>
            <tr>
                <td>
                    Analitico
                </td>
                <td>
                    TOURIAL-C
                </td>
                <td>
                    Tourial
                </td>
                <td>
                    6 mesi
                </td>
            </tr>

            <!-- GOOGLE ANALYTICS -->
            <tr>
                <td>
                    Analitico
                </td>
                <td>
                    _ga
                </td>
                <td>
                    Google
                </td>
                <td>
                    2 anni
                </td>
            </tr>
            <tr>
                <td>
                    Analitico
                </td>
                <td>
                    _ga_{container_id}
                </td>
                <td>
                    Google
                </td>
                <td>
                    2 anni
                </td>
            </tr>
        </template>
    </vs-table>

    <p>Nella finestra “visualizza i cookies” potrà trovare il dettaglio dei cookies e selezionare le Sue preferenze, fatta eccezione per i cookies tecnici.</p>
    <p>Per informazioni più dettagliate e inerenti il presente sito, si veda quanto descritto nell'informativa specifica, accessibile dalla sezione “Show cookies/Mostra i cookies”, operativa nel banner in homepage.</p>
    <p>Se l'utente decidesse di disabilitare i cookies, ciò potrebbe influenzare e/o limitare la sua esperienza di navigazione all'interno del sito: per esempio, potrebbe non essere in grado di visitare determinate sezioni del sito o potrebbe non ricevere informazioni personalizzate quando visita il sito. I cookies tecnici non possono essere disabilitati.</p>

    <h4>Manifestazione del consenso per accettazione dei cookies</h4>
    <p>Il consenso può essere acquisito: </p>
    <ul>
        <li>mediante selezione di un'apposita casella nel sito web</li>
        <li>attraverso la scelta di impostazioni tecniche per servizi della società dell'informazione</li>
        <li>attraverso qualsiasi dichiarazione o comportamento che indichi chiaramente che si accetta il trattamento proposto</li>
    </ul>

    <h4>Manifestazione del consenso attraverso l'apposita casella sul sito</h4>
    <p>Il consenso può essere validamente acquisito mediante la selezione di appositi flag sul banner del sito web, che compaiono al momento dell'apertura della pagina web, per finalità di tracciamento on line delle informazioni che riguardano l'utente, anche derivanti dall'uso di cookie ed altri strumenti di tracciamento.</p>
    <p>Qualora l'utente scegliesse di mantenere le impostazioni di default, può limitarsi a chiudere il banner mediante selezione di apposito comando (una X posizionata di regola in alto a destra ed all'interno del banner medesimo). Anche tale azione, esprimendo la volontà di non acconsentire all'utilizzo di cookie diversi da quelli tecnici, impedirà al sito la reiterazione della presentazione del banner in occasione di successivi accessi dell'utente.</p>
    <p>Il banner dovrà altresì contenere l'avvertenza che la sua chiusura attraverso la X posta in alto a destra comporta il permanere delle impostazioni di default e quindi l'assenza di operatività di cookie o altri strumenti di tracciamento diversi da quelli tecnici</p>
    <p>Le impostazioni devono essere in ogni momento modificabili dall'utente, tramite la fruizione di apposita funzionalità resa operativa nella prima pagina del sito (quale, ad esempio, tasto “Rivedi le tue scelte”) </p>
    <p>Nel caso quindi in cui l'utente mantenga le impostazioni di default e dunque non acconsenta all'impiego di cookie o altri strumenti di tracciamento, così come nel caso in cui abbia acconsentito solo all'impiego di alcuni cookie o di alcuni strumenti di tracciamento, tale scelta dovrà essere debitamente registrata e la prestazione del consenso non più nuovamente sollecitata se non quando ricorra uno dei seguenti casi: a) quando mutino significativamente una o più condizioni del trattamento; b) quando sia impossibile per il gestore avere contezza del fatto che un cookie sia stato già in precedenza memorizzato sul dispositivo per essere nuovamente trasmesso; c) quando siano trascorsi almeno 6 mesi dalla precedente presentazione del banner.</p>

    <h4>Manifestazione del consenso mediante la scelta di impostazioni tecniche per servizi della società dell'informazione.</h4>
    <p>Le modalità di funzionamento nonché le opzioni per limitare o bloccare i cookie, possono essere regolate modificando le impostazioni del proprio browser internet. La maggioranza dei browser internet sono inizialmente impostati per accettare i cookie in modo automatico, l'utente può tuttavia modificare queste impostazioni per bloccare i cookie o per essere avvertito ogniqualvolta dei cookie vengano inviati al suo dispositivo. Esistono diversi modi per gestire i cookie, a tal fine occorre fare riferimento alla schermata di aiuto del proprio browser per verificare come regolare o modificare le impostazioni dello stesso. L'utente è abilitato, infatti, a modificare la configurazione predefinita e disabilitare i cookie (cioè bloccarli in via definitiva), impostando il livello di protezione più elevato.</p>
    <p>Qui sotto il percorso da seguire per gestire i cookie dai seguenti browser:</p>
    <p>Chrome: https://support.google.com/chrome/answer/95647?hl=it-IT&hlrm=fr&hlrm=en</p>
    <p>InternetExplorer: http://windows.microsoft.com/it-it/windows7/block-enable-or-allow-cookies</p>
    <p>Safari: http://support.apple.com/kb/PH11913</p>
    <p>Firefox: http://support.mozilla.org/it-IT/kb/enable-and-disable-cookies-website-preferences</p>

    <p>Se l'utente usa dispositivi diversi tra loro per visualizzare e accedere ai siti (per esempio, computer, smartphone, tablet, ecc.), deve assicurarsi che ciascun browser su ciascun dispositivo sia regolato per riflettere le proprie preferenze relative ai cookie. Per eliminare i cookie dal browser internet del proprio smartphone/tablet è necessario fare riferimento al manuale d'uso del dispositivo.</p>
    <p>Ulteriori chiarimenti sono disponibili al sito internet del Garante Privacy. Per ulteriori informazioni o domande sulla politica della privacy di questo sito La preghiamo di contattarci via email all'indirizzo di posta elettronica elena.colombo@tourial.it</p>
</div>
</template>

<script>
export default {
    name: "cookiepolicy",
    props: {
        innerHeight: Number
    },
    components: {},
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>


<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.cookiepolicy-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    height: calc(100% - 75px);
    overflow-x: hidden;
    box-sizing: border-box;
}

.cookiepolicy-container.full-page {
    height: 100%;
    padding-bottom: 40px;
    padding-left: 70px;
    padding-right: 20px;
}

p,
h3 {
    margin: 0;
}

h3 {
    margin-top: 20px;
}

/* MOBILE */
@media (max-width: 600px) {
    .top {
        margin-top: 20px;
    }
    .cookiepolicy-container {
        padding-top: 20px;
        padding-left: 20px !important;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 90px !important;
        width: 100% !important;
        overflow-x: hidden;
    }

}
</style>
